<template>
  <div class="ft20">
    <div class="flex space alcenter">
      <div class="ft20 cl-main ftw600">{{active_name}}附件管理</div>
      <a-button @click="$router.go(-1)">返回</a-button>
    </div>
    <div class="mt20">
      <div class="pd20 bg-w">
        <a-button type="primary" icon="plus" @click="AddAct()">上传附件</a-button>
        <div class="wxb-table-gray mt10">
          <a-table rowKey="look_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
            <div class="flex alcenter left" slot="file_url" slot-scope="record,index">
              <div class="ml5" style="text-align: left;">
                {{record.file_url}}
                <a-button type="link" @click="onLink(record)">下载</a-button>
              </div>
            </div>
          </a-table>
        </div>
      </div>
    </div>

    <div v-if="addLoading">
      <add-files :visible="addLoading" :active_id="active_id" :active_name="active_name" @cancel="cancelMod" @ok="okAddFiles"></add-files>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
import addFiles from './components/addFiles.vue';

export default {
  mixins: [listMixin],
  components:{
    addFiles,
  },
  data() {
    return {
      active_id:0,
      active_name:'',
      loading:false,
      addLoading:false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      columns: [
        {title: 'ID',dataIndex: 'id',align: 'center',width:100,ellipsis: true},
        {title: '附件名称',dataIndex: 'file_name',align: 'left',width:180,ellipsis: true},
        {title: '附件',key: 'file_url',align: 'left',scopedSlots: {customRender: 'file_url'}},
        {title: '添加时间',dataIndex: 'add_time_format',align: 'center',width:180,ellipsis: true},
      ],
      datas: [],
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.active_id=0;
    } else {
      this.active_id = parseInt(this.$route.query.id);
    }
    if (this.$route.query.name) {
      this.active_name = this.$route.query.name;
    }
  },
  methods: {
    onLink(record){
      window.open(record.file_url)
    },
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/getActivityFilesList',{
        active_id:parseInt(this.$route.query.id),
        limit:this.pagination.pageSize,
        page:this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },

    AddAct() {
      this.addLoading = true;
    },
    cancelMod(){
      this.addLoading = false;
    },
    okAddFiles(){
      this.addLoading = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },
  }
}
</script>

<style>
@media screen and (max-width: 1450px) {
  .look-index-item {
    border: 1px solid #EBEDF5;
    width: 99%;
    height: 140px;
    margin-right: 1%;
    margin-bottom: 1%;
    padding: 10px;
  }
}

@media screen and (min-width:1451px) {
  .look-index-item {
    border: 1px solid #EBEDF5;
    width: 49%;
    height: 140px;
    margin-right: 1%;
    margin-bottom: 1%;
    padding: 10px;
  }
}

.look-index-item:hover {
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.look-index-item-operate {
  display: none;
}

.look-index-item:hover .look-index-item-operate {
  display: block;
}

.look-index-item-cover-img {
  width: 120px;
  height: 80px;
}

.look-index-item-cover-img-num {
  position: absolute;
  right: 18px;
  bottom: 8px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 1px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.5;
}

.look-index-item-operate-button {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  cursor: pointer;
}
</style>
