<template>
	<div>
		<a-modal :title="getTitle" :width="480" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">

        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-form-model-item required label="附件">
              <a-upload
                  name="file"
                  :showUploadList="false"
                  :customRequest="upload"
                  :beforeUpload="beforeUpload"
              >
                <a-button>
                <a-icon type="upload" />点击上传</a-button>
              </a-upload>
            </a-form-model-item>
          </a-row>

          <a-row>
            <a-form-model-item required label="附件名">
              <a-input style="width: 200px;" placeholder="请输入附件名称" v-model="form.file_name" :maxLength="20">
              </a-input>
            </a-form-model-item>
          </a-row>

        </a-form-model>

			</a-spin>
		</a-modal>
	</div>
</template>

<script>
import {uploadfiles} from "../../../common/request/request";
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
      active_id: {
				type: Number,
				default: 0,
			},
      active_name:{
        type:String,
        default:'',
      },
		},
		components: {},
		data() {
			return {
				loading: false,
				confirmLoading: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
				form: {
          file_url: '',
          file_name: '',
				},
			}
		},
		computed: {
			getTitle() {
        return this.active_name+" 添加附件"
			}
		},
		created() {
			this.loaddata();
		},
		methods: {

			loaddata(){
				if(this.loading==true) return;
			},

      upload(e) {
        let file = e.file;
        this.loading = true;
        this.$http.uploadfiles(file,'admin/filesImport').then(res=>{
          this.loading = false;
          if(res.code == 200){
            this.$message.success(res.msg);
            this.form.file_url = res.data;
          }else{
            this.msgArr = res.data;
          }
        }).catch(res=>{
          this.loading = false;
        });
      },

      beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('最大为2M');
          return false;
        }
        return isLt2M;
      },

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/filesSave',{
          active_id:this.active_id,
          file_name:this.form.file_name,
          file_url:this.form.file_url,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
            this.$emit("cancel");
            this.refreshPage('/activity/fileActivity?id='+this.active_id+'&name='+this.active_name);
					})

				}).catch(res=>{
					this.confirmLoading=false;
				})

			},

		}
	}
</script>

<style>

</style>
